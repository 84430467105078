$middle-gray: #8a8a8a;

$bluey-grey: #9e9dab;
$pip-of-blue: #6a6aa1;
$action-active: rgba(0, 0, 31, 0.54);
$light-periwinkle-two: #d6d6d9;
$pale-grey: #f1f1f6;
$greyish-brown: #4a4a4a;

// role-game
$dusty-teal: #49997c;
$charcoal-grey: #3c4d46;
$seafoam-blue: #62cca5;
