/* You can add global styles to this file, and also import other style files */
@import "themes";
@import "color-constants";
@import "theme-module";
@import "~quill/dist/quill.snow.css";
@import "~quill/dist/quill.bubble.css";
@import "~quill/dist/quill.core.css";

* {
  font-family: "Roboto", sans-serif;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  text-size-adjust: none;
  --webkit-text-size-adjust: none;

  &:active,
  &:focus {
    outline: none !important;
  }
}

html {
  min-height: 100vh;
  height: 100%;
}

body {
  min-height: 100vh;
  height: 100%;
  margin: 0;
  background: var(--tellsy-text-background);
}

a {
  word-wrap: break-word;
}

mat-icon {
  overflow: hidden;
}

.monospace {
  font-family: "Roboto Mono", monospace;
}

.bold {
  font-weight: 700;
}

.regular {
  font-weight: 400;
}

.extra-bold {
  font-weight: 800;
}

.light {
  font-weight: 300;
}

.large-text {
  font-size: 40px !important;
}

.middle-text {
  font-size: 18px;
}

.fs-16 {
  font-size: 16px;
}

.small-text {
  font-size: 14px;
}

.mobile-text-body {
  font-size: 16px;
  line-height: 21px;
}

.white-space-pre-wrap {
  white-space: pre-wrap;
}

.spacer {
  flex: 1 1 auto;
}

.success {
  background-color: var(--tellsy-correct);
}

.primary-color {
  color: var(--tellsy-primary-dark);
}

.primary-color-bg {
  background-color: var(--tellsy-primary-dark);
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-text {
  cursor: text;
}

.small-text-12 {
  font-size: 12px;
}

.small-text-13 {
  font-size: 13px;
}

.mat-drawer-container {
  background-color: var(--tellsy-text-background);
}

.gun-text {
  color: var(--tellsy-text-primary) !important;
}

.light-text {
  color: var(--tellsy-primary);
}

.animated {
  transition: all 0.3s linear;
}

.with-shadow {
  box-shadow: 0 1px 4px 0 $light-periwinkle-two;
}

.uppercase {
  text-transform: uppercase;
}

.padding-0 {
  padding: 0 !important;
}

.card-container-title {
  font-size: 14px;
  margin: 0 0 6px 16px;
  color: var(--tellsy-text-primary-dark);
}

.mat-dialog-container {
  background: var(--tellsy-text-background);
  max-height: 90vh !important;
}

.ql-editor {
  color: var(--tellsy-text-primary);

  img {
    max-width: 100%;
    cursor: default;
  }

  p strong em {
    font-weight: inherit;
  }
}

.cc-window {
  border: 1px solid #9797973d;
}

.notification {
  width: 300px !important;
  border-radius: 3px !important;
}

.simple-notification-wrapper {
  margin-top: 40px;

  .sn-title {
    font-size: 14px !important;
    line-height: 24px !important;
  }

  .simple-notification-svg {
    width: 24px !important;
    height: 24px !important;
    font-size: 24px !important;
  }
}

.outline-no_shadow > mat-card {
  box-shadow: none !important;
  border: 1px solid #d8dde6;
}

.flow-button-host {
  position: sticky;
  left: 100%;
  width: 48px;
  height: 0;
  z-index: 20;

  .flow-button {
    width: 48px;
    height: 48px;
    padding: 12px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    box-shadow: 0 2px 3px 0 #9b9b9b;
    transition: all 0.2s ease-in-out;
    cursor: pointer;

    animation-direction: normal;
    animation-duration: 0.2s;
    animation-timing-function: ease-in-out;
    animation-delay: 0s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;

    svg {
      fill: white !important;
      color: white !important;
    }

    &.enter {
      animation-name: entering;
    }

    &.leave {
      animation-name: leaving;
    }

    @keyframes entering {
      0% {
        transform: translateX(80px);
        opacity: 0;
        visibility: hidden;
      }

      25% {
        transform: translateX(60px);
        opacity: 0.25;
        visibility: visible;
      }

      50% {
        transform: translateX(40px);
        opacity: 0.5;
        visibility: visible;
      }

      75% {
        transform: translateX(20px);
        opacity: 0.75;
        visibility: visible;
      }

      100% {
        transform: translateX(0);
        opacity: 1;
        visibility: visible;
      }
    }

    @keyframes leaving {
      0% {
        transform: translateX(0);
        opacity: 1;
        visibility: visible;
      }

      25% {
        transform: translateX(20px);
        opacity: 0.75;
        visibility: visible;
      }

      50% {
        transform: translateX(40px);
        opacity: 0.5;
        visibility: visible;
      }

      75% {
        transform: translateX(60px);
        opacity: 0.25;
        visibility: visible;
      }

      100% {
        transform: translateX(80px);
        opacity: 0;
        visibility: hidden;
      }
    }
  }
}
