@use "@angular/material" as mat;

@include mat.core();

.mat-checkbox-layout {
  white-space: normal !important;
}

.mat-radio-label {
  white-space: normal !important;
}

$md-brand: (
  50: #e4e3ed,
  100: #bcb8d1,
  200: #9089b3,
  300: #635a95,
  400: #41367e,
  500: #201367,
  600: #1c115f,
  700: #180e54,
  800: #130b4a,
  900: #0b0639,
  A100: #7a70ff,
  A200: #4b3dff,
  A400: #1c0aff,
  A700: #1100f0,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #ffffff,
    A400: #ffffff,
    A700: #ffffff,
  ),
);
$md-orange: (
  50: #fff2e0,
  100: #ffdfb3,
  200: #ffca80,
  300: #ffb54d,
  400: #ffa526,
  500: #ff9500,
  600: #ff8d00,
  700: #ff8200,
  800: #ff7800,
  900: #ff6700,
  A100: #ffffff,
  A200: #fff7f2,
  A400: #ffd5bf,
  A700: #ffc4a6,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);
$app-primary: mat.define-palette($md-brand);
$app-accent: mat.define-palette($md-orange);
$app-warn: mat.define-palette(mat.$red-palette);
$app-theme: mat.define-light-theme(
  (
    color: (
      primary: $app-primary,
      accent: $app-accent,
      warn: $app-warn,
    ),
    typography:
      mat.define-typography-config(
        $font-family: "Roboto, sans-serif",
      ),
    density: 0,
  )
);

@include mat.all-component-themes($app-theme);
@include mat.all-legacy-component-themes(
  $app-theme
); // remove after moving to MDC components https://material.angular.io/guide/mdc-migration
