@import "color-constants";

.mat-menu-panel {
  box-shadow: 0 2px 3px 0 $light-periwinkle-two !important;
  border: solid 1px $pale-grey !important;
  padding: 0;

  & .mat-menu-content {
    padding: 0 !important;
    min-width: 169px;
  }

  & .mat-divider {
    border-top-color: $pale-grey;
  }

  & .mat-checkbox-layout {
    width: 100%;

    & .mat-checkbox-label {
      width: 100%;
    }
  }

  & button {
    height: 36px;
    line-height: 1;
    font-size: 13px;
  }

  @media all and (max-width: 1366px) {
    & button {
      height: 48px;
      line-height: 1;
    }
  }
}

.mat-tooltip {
  background-color: var(--tellsy-text-background) !important;
  color: var(--tellsy-text-primary) !important;
  border-radius: 4px;
  box-shadow: 0 0 7px 0.6px rgba(0, 0, 0, 0.5);
  font-size: 14px;
}

.mat-select-panel {
  &.participant {
    border-radius: 18px;
    box-shadow: 0px 4px 6px rgba(43, 37, 78, 0.16);
    background-color: white;
    min-width: calc(100% + 24px) !important;
    margin-left: 4px;
    margin-top: 30px;
    padding: 8px 0;

    .mat-option {
      padding: 6px 16px;
      max-height: 36px;

      .mat-option-text {
        color: var(--tellsy-text-primary-dark-participant) !important;
        font-size: 16px;
        line-height: 132%;
      }

      &.mat-selected {
        background-color: var(--tellsy-primary-light-opaque-participant);
      }
      &.arrow-select {
        display: none;
      }

      &:hover {
        background-color: var(--tellsy-primary-light-opaque-participant);
      }

      &.mat-disabled {
        color: var(--tellsy-text-disabled-participant);
      }

      .mat-option-ripple > * {
        background-color: var(--tellsy-primary-light-opaque-participant);
      }
    }
  }
}

@media screen and (max-width: 820px) and (max-height: 1180px) {
  .mat-select-panel {
    &.participant {
      .mat-option {
        &.arrow-select {
          display: flex;
          justify-content: center;
          position: sticky;
          top: -8px;
          background-color: white;
          font-weight: bold;
          margin-top: -8px;
          z-index: 10;
        }
      }
    }
  }
}

.error-hint {
  font-size: 11px !important;
  padding-left: 12px;
  color: var(--tellsy-error) !important;
}

.mat-card {
  box-shadow: 0 1px 4px 0 $light-periwinkle-two !important;
}

.big-title {
  font-weight: bold;
  font-size: 17px;
  color: var(--tellsy-primary-dark);
}

.middle-title {
  font-weight: bold;
  font-size: 15px;
  color: var(--tellsy-text-primary-dark);
}

$spaceamounts: (
  0,
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  25,
  30,
  35,
  40,
  50
);
$sides: (top, bottom, left, right);

@each $space in $spaceamounts {
  @each $side in $sides {
    .m#{str-slice($side, 0, 1)}-#{$space} {
      margin-#{$side}: #{$space}px !important;
    }

    .p#{str-slice($side, 0, 1)}-#{$space} {
      padding-#{$side}: #{$space}px !important;
    }
  }
}

//Default Scroll//
*::-webkit-scrollbar-track {
  background-color: $pale-grey;
}

*::-webkit-scrollbar {
  width: 9px;
  background-color: $pale-grey;
}

*::-webkit-scrollbar-thumb {
  background-color: $light-periwinkle-two;
}

//ShowScroll//
*::-webkit-scrollbar-track:hover {
  background-color: $pale-grey;
}

*::-webkit-scrollbar:hover {
  width: 9px;
  background-color: $pale-grey;
}

*::-webkit-scrollbar-thumb:hover {
  border-radius: 5.5px;
  background-color: $light-periwinkle-two;
}
